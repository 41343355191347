import React from "react"
import CoursePage from "../../../components/Course/CoursePage"
import { Box, Text } from "theme-ui"

const PostelCourse = (): JSX.Element => {
  return (
    <CoursePage
      heading="Postal "
      courses={[
        {
          name: "Complete Course (GS Pre Cum Mains + CSAT, Optional Subject)",
          price: "Rs.28,500/-",
          duration: "11 Month",
        },
        {
          name: "Pre Cum Mains Course (GS Pre Cum Mains + CSAT)",
          price: "Rs.15,000/-",
          duration: "10 Month",
        },
        {
          name: "Foundation Course",
          price: "Rs.35,000/-",
          duration: "36 Months",
        },
        {
          name: "Optional Subject Course",
          price: "Rs.18,000/-",
          duration: "04 & Half Month",
        },
        {
          name: "Weekend Course",
          price: "Rs. 25,000/-",
          duration: "15 Months",
        },
      ]}
      description={
        <Box>
          <Text sx={{ fontSize: 3 }}>
            Now it’s your choose of postal class to prepare UPSC, our hands join
            with you and with your big dream to success.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Effectively Study material of sarkar ias academy will be sent
            through courier in the postal address of our student after the
            joining of postal.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Day to day Study schedule prepared by sarkar will be attached
            Efficiently with our faculty’s quid line. It’s to help the
            preparation of yourself.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Periodically postal students can participate test series also. It
            will help personally to renovate yourself.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            If arise any doubts and quires in particular subject and topic, can
            contact every student through mail personally created by yourself
            for academic purpose with our registration id number’s help to the
            official mail of sarker as text or video format. Your valuable
            quires will be rectified with our faculty’s explanation of
            particular subject or topic.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Current affairs and all notification about UPSC will have been
            published in our official website and at the time also directly will
            be sent to your official academic mail id, Sarkar whatsapp group and
            telegram group
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            At the end of every week, all rectified quires and questions will be
            telecasted in our official sarkar YouTube channel.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Seminar session and free webinar classes also are conducted
            including for the postal student once in every month.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Now may start your UPSC Carrier with our faculty’s guidelines and
            Study materials.
          </Text>
        </Box>
      }
    />
  )
}

export default PostelCourse
