import React from "react"
import Layout from "../../components/Layout"
import { Box, Grid, Text, Flex, Button, Heading, Card, jsx } from "theme-ui"
import SEO from "../../components/SEO"
import PageHeader from "../../components/PageHeader"
import ContentSection from "../../components/ContentSection"
import SpecialityGrid from "../../components/SpecialityGrid"
import RegisterToday from "../RegisterToday"

interface CourseInterface {
  name: string
  price: string
  duration: string
}

type CourseCardProps = CourseInterface

const CourseCard = (props: CourseCardProps) => {
  const { name, price, duration } = props

  return (
    <Card p={3} sx={{ maxWidth: "350px" }} variant="shadow2">
      <Flex sx={{ alignItems: "center", height: "50px" }}>
        <Text sx={{ fontSize: 3 }}>{name}</Text>
      </Flex>
      <Heading mt={3} sx={{ fontSize: 4 }} color="primary">
        {price}
      </Heading>
      <Text mt={2} variant="secondary">
        {duration}
      </Text>
    </Card>
  )
}

interface InclusionCardProps {
  text: string
}

const InclusionCard = (props: InclusionCardProps) => {
  const { text } = props

  return (
    <Flex
      bg="#fff"
      py={3}
      px={4}
      sx={{
        width: "300px",
        height: "120px",
        border: "1px solid #70707071",
        fontSize: 3,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {text}
    </Flex>
  )
}

interface CoursePageProps {
  heading: string
  courses: CourseInterface[]
  description: React.ReactNode
}

const CoursePage = (props: CoursePageProps) => {
  const { courses, heading, description } = props

  return (
    <Box>
      <SEO title={heading} />
      <Layout>
        <PageHeader>
          <Text sx={{ fontSize: [1, 3], fontStyle: "italic" }}>
            SARKAR IAS ACADEMY is one of the most Trusted, Technically
            well-equipped institutions in Chennai for coaching UPSC,TNPSC, CIVIL
            ASPIRANTS WITH FREE INTERVIEW AND HIGHLY-QUALITY EDUCATIONAL
            RESOURCES CAN BE PRESENTED AS METICULOUSLY CRAFTED STUDY MATERIALS.
          </Text>
        </PageHeader>
        <ContentSection
          headingElement={
            <Heading sx={{ textAlign: "center", fontSize: [5, null, 6] }}>
              {heading}
            </Heading>
          }
          body={
            <Grid
              pt={4}
              columns={[1, 2, 3]}
              gap={5}
              sx={{ maxWidth: "1200px", margin: "auto" }}
            >
              {courses.map(course => (
                <CourseCard
                  name={course.name}
                  price={course.price}
                  duration={course.duration}
                />
              ))}
            </Grid>
          }
        />

        {/* Course Description */}
        <ContentSection
          header="Course Description"
          noHeaderBorder={true}
          body={
            <Box color="accent" sx={{ maxWidth: "800px", margin: "auto" }}>
              {description}
            </Box>
          }
        />

        {/* Course Inclusions */}
        <Box pt={1} pb={3} bg="#DEDEDE3E">
          <ContentSection
            header="Course Inclusions"
            noHeaderBorder={true}
            body={
              <Grid
                columns={[1, 2, 3]}
                sx={{
                  gridRowGap: 4,
                  maxWidth: "1000px",
                  margin: "auto",
                  justifyItems: "center",
                }}
              >
                <InclusionCard text="Primary Study Material" />
                <InclusionCard text="Sarkar Magazine" />
                <InclusionCard text="Monthly Current Affairs Test" />
                <InclusionCard text="Prelims Test series" />
                <InclusionCard text="Mains Test series" />
                <InclusionCard text="Mock Interviews" />
                <InclusionCard text="General studies Test" />
                <InclusionCard text="Guest Lectures" />
                <InclusionCard text="Doubts Sessions" />
              </Grid>
            }
          />
        </Box>

        {/* Why Sarkar IAS?*/}
        <ContentSection
          header="Why Sarkar IAS ACADEMY?"
          noHeaderBorder={true}
          body={<SpecialityGrid />}
        />

        {/* Register Today */}
        <RegisterToday />
      </Layout>
    </Box>
  )
}

export default CoursePage
